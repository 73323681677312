<template lang="pug">
    layout_modal
        header.flex_content-vertical.jc_flex-end
            .bg.image(:style="service.img|ress|background")
            .wrapper
                .sec_title.sec_title--secondary
                    span {{ service.pk }}
                    h3 {{ service.type }}
                h1.text--xxxl.text--ls-sm {{ service.name }}
        section
            .wrapper
                .margin-bottom--xxl(v-html="service.description")
                router-link(to="/contacto").btn.btn--secondary
                    span Contactanos
</template>

<script>
import Modal from '@/views/layouts/Modal'

export default {
    name: 'modal_service',
    props: ['pk'],
    components: {
        layout_modal: Modal
    },
    computed: {
        service() {
            return this.$store.getters.singleService(this.pk)
        }
    },
    metaInfo() {
        return {
            title: this.service.name,
            meta: this.service.meta
        }
    }
}
</script>