<template lang="pug">
    .modal(@click.stop="close")
        article
            .modal_container(@click.stop="").medium
                slot
                a(@click.stop.prevent="close").close-modal
                    i.icon-close.icon--xl
                    span.off_screen Cerrar
</template>

<script>
export default {
    name: 'layout_modal',
    beforeMount() {
        document.body.classList.add("modal_active")
    },
    methods: {
        close() {
            let index = this.$route.matched.length - 2
            this.$router.push({
                name: this.$route.matched[index].name
            })
        }
    },
    created() {
      const onEscape = e => {
        if (e.keyCode === 27) {
          this.close()
        }
      }
      document.addEventListener('keydown', onEscape)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', onEscape)
      })
    }
}
</script>